export const AWS_CONFIG = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_GNXe8O8ow',
    userPoolWebClientId: '4niqq5iiaj8n7j1prncfqn1duu',
    identityPoolId: 'us-east-1:c1240333-5d8e-4b8c-aa3d-e3eedc4975c4',
  };


  // src/config.js
export const COGNITO_CONFIG = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_GNXe8O8ow', // Your User Pool ID
    userPoolWebClientId: '4niqq5iiaj8n7j1prncfqn1duu',
    domain: 'https://viralclip.auth.us-east-1.amazoncognito.com',
    redirectSignIn: 'https://viralclip.live', // Your app's URL
    redirectSignOut: 'https://viralclip.live',
    responseType: 'code',
    scope: 'openid profile email',
    identityPoolId: 'us-east-1:c1240333-5d8e-4b8c-aa3d-e3eedc4975c4',
  };
  