// src/App.js
import React, { useState, useEffect } from 'react';
import { login, logout, handleAuthentication } from './Auth';
import VideoUpload from './VideoUpload';
import UserClips from './UserClips'; 
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0); 

  useEffect(() => {
    const initAuth = async () => {
      const identityId = await handleAuthentication();
      if (identityId) {
        setUser(identityId);
        window.history.replaceState({}, document.title, '/');
      }
    };
    initAuth();
  }, []);

  const handleLogin = () => {
    login();
  };

  const handleLogout = () => {
    logout();
    setUser(null);
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  const handleRefresh = () => {
    setRefreshCount((prevCount) => prevCount + 1);
  };

  return (
    <>
      <div class="welcomemsg">
        Welcome to ViralClip.live
      </div>
      <div className="App">
        <h1>Video Uploader</h1>
        {user ? (
          <>
            {/* <p>Welcome, user with identity ID: {user}</p> */}
            <div style={{ marginTop: '20px' }}>
              <button onClick={handleLogout}>Logout</button>
              <button onClick={handleRefresh} style={{ marginLeft: '10px' }}>
                Refresh Clips
              </button>
            </div>
            <div>
            <VideoUpload />
            </div>
          </>
        ) : (
          <button onClick={handleLogin}>Login</button>
        )}
      </div>
      {user && <UserClips userId={user} refresh={refreshCount} />}
    </>
  );
  
}

export default App;
